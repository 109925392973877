import React, { useEffect, useState } from "react"
import styled from "styled-components"
import jump from "jump.js"

import Layout from "src/layout"
import { absolute } from "src/helpers/styled"
import ExpandIcon from "src/components/Expand"
import InstagramIcon from "src/components/Instagram"

const Splash = styled.section`
  padding: 0;
  position: relative;
  button {
    display: none;
  }

  @media (min-width: 800px) {
    height: 42.857vw; /* 21/9 */
  }

  @media (min-width: 1300px) {
    button {
      display: initial;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -36px;
      padding: 0;
      z-index: 2;
      svg {
        width: 72px;
        height: 72px;
        fill: white;
      }
    }
  }
`
const Video = styled.div`
  position: relative;
  height: 50vw;
  overflow: hidden;

  @media (min-width: 800px) {
    height: auto;
    ${absolute}
  }
`
const Player = styled.iframe`
  /* ${absolute} ant, removed to fix video alignement issue on big screens */
  position: relative;
  width: 100%;
  height: calc(100% + 200px);
  top: -100px;
  max-width: 1920px;
  max-height: 1080px;

  @media (min-width: 800px) {
    pointer-events: none;
  }
`
const Content = styled.div<{ $hidden?: boolean }>`
  text-shadow: 0 1px 5px black;

  h1 {
    font-size: 3em;
  }
  h2 {
    font-size: 2em;
  }

  @media (min-width: 800px) {
    ${absolute}
    opacity: ${({ $hidden }) => ($hidden ? 0 : 1)};
    transition: opacity 4s ease-in-out;
    z-index: 1;
    margin-top: 10vw;
    h1 {
      font-size: 4.5em;
    }
    h2 {
      font-size: 3em;
    }
  }
`
const Section = styled.section`
  h3 {
    font-size: 2em;
  }
  p {
    font-size: 1.2em;
  }
  margin-bottom: 100px;
`
const Tagline = styled.h3`
  margin: 2em;
`
const SmallPlayer = styled.iframe`
  width: 100%;
  height: 56.25vw; /* 16/9 */
  max-width: 800px;
  max-height: 450px;

  @media (min-width: 800px) {
    width: 60vw;
    height: 33.75vw;
  }
`
const Clients = styled.p`
  margin: 3em;
`
const ExternalLink = styled.a`
  svg {
    width: 48px;
    height: 48px;
    fill: white;
    margin-top: 10px;
  }
`

const musicvideo: GatsbyPage = () => {
  const [hiddenTitle, setHidden] = useState(false)

  useEffect(() => {
    if (window.innerWidth < 500) {
      return
    }

    const onMessage = (event: MessageEvent) => {
      if (event.data.time) {
        setHidden(event.data.time > 7)
      }
    }

    window.addEventListener("message", onMessage)
    return () => {
      window.removeEventListener("message", onMessage)
    }
  }, [])

  const handleJump = () => {
    jump("#next")
  }

  return (
    <Layout>

      <Section id="next">
        <Tagline>Music Video</Tagline>
        <SmallPlayer
          src="https://youtube-iframe.netlify.app/?v=8UFSI7QNINE&rel=0&modestbranding=1"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <Clients>"Split", a story of a fight against oneself </Clients>
        <h3>Contact : Hadrien Finel</h3>
        <p>
          Téléphone : <a href="tel:+33645096847">+33 6 45 09 68 47</a>
        </p>
        <p>
          E-mail : <a href="mailto:hadrien.finel@gmail.com">hadrien.finel@gmail.com</a>
        </p>
        <p>
          <ExternalLink href="https://www.instagram.com/whisper117s/" title="Instagram">
            <InstagramIcon />
          </ExternalLink>
        </p>
      </Section>
    </Layout>
  )
}

export default musicvideo
